/** @jsx jsx */
import React from "react";
import { Box, jsx } from "theme-ui";
import { RichText } from "prismic-reactjs";
import ContentContainer from "../container/content-container";
import styles from "./info-page.style";
import ColorDivider from "../color-divider/color-divider";
import { linkResolver }from "../../utils/linkResolver"

type PropsType = {
  title?: string;
  bodyText?: any;
};

const InfoPage: React.FC<PropsType> = ({ title, bodyText }) => {
  const text = RichText.render(bodyText, linkResolver);
  return (
    <ContentContainer width="100%">
      <Box sx={styles.wrapper}>
        <h1>{title}</h1>
        {text}
      </Box>
      <ColorDivider color="cgMustard" />
    </ContentContainer>
  );
};

export default InfoPage;
