const styles = {
  wrapper: {
    margin: "0 auto !important",
    paddingTop: '2rem',
    width: "100%",
    '@media only screen and (min-width: 890px)': {
      width: "90%",
    },
    '@media only screen and (min-width: 1100px)': {
      width: "73%",
    },
    h1: {
      color: 'headingColor',
      fontWeight: 400,
      fontSize: '26px',
      '@media only screen and (min-width: 560px)': {
        fontSize: '38px',
      },
      '@media only screen and (min-width: 890px)': {
        fontSize: '42px',
      },
    },
    a: {
      color: 'inherit',
      '&:hover, &:focus': {
        color: '#D79A2B',
      },
    },
    h2: {
      fontSize: "1.7rem",
      "@media only screen and (min-width: 768px)": {
        fontSize: "2rem",
      },
    },
    h3: {
      fontSize: "1.53rem",
      lineHeight: "1.1em",
      "@media only screen and (min-width: 768px)": {
        fontSize: "1.75rem",
      },
    },
    h4: {
      fontSize: "1.45rem",
      "@media only screen and (min-width: 768px)": {
        fontSize: "1.65rem",
      },
    },
    h5: {
      fontSize: "1.38rem",
      "@media only screen and (min-width: 768px)": {
        fontSize: "1.45rem",
      },
    },
    h6: {
      fontSize: "1.15rem",
      "@media only screen and (min-width: 768px)": {
        fontSize: "1.25rem",
      },
    },
    "h2, h3, h4, h5, h6": {
      color: "#054752",
      fontWeight: 400,
      marginTop: "0",
      marginBottom: ".5rem",
    },
    p: {
      marginTop: "0",
      marginBottom: "25px",
      fontSize: "18px",
      lineHeight: '29px',
    },
  },
};

export default styles;