import React from "react";
import PrimaryLayout from "../components/layout/primary/primary";
import { graphql } from "gatsby";
import InfoPage from "../components/info-page/info-page";
import SEO from "../components/seo";

export const query = graphql`
  query infoPageBySlug($uid: String!) {
    main: prismicInfoPages(uid: {eq: $uid}) {
      uid
      data {
        title {
          text
        }
        text {
          raw
        }
        meta_description
      }
    }
  }
`;
const InfoPageTemplate: React.FC<{}> = ({ data }) => {
  const prismicContent = data.main.data;
  if (!prismicContent) return null;
  const title = prismicContent.title.text
  const bodyText = prismicContent.text.raw
  const meta = prismicContent.meta_description

  return (
    <PrimaryLayout shop>
      <SEO title={title} description={meta}/>
      <InfoPage title={title} bodyText={bodyText} />
    </PrimaryLayout>
  );
};

export default InfoPageTemplate;
